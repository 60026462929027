import React from 'react';
import {
  Heading,
  IconCertificate,
  IconCompleted,
  IconEducation,
  IconEducationNew,
  PropertyGroup,
} from '../../components';

import css from './ListingPage.module.css';

const SectionMultiEnumMaybe = props => {
  const {
    heading,
    options,
    selectedOptions,
    categoryPreviousOther,
    trueTrainingComp,
    currentCertification,
    levelOfEducation,
    otherLevelEducationContent,
    otherCurrentCertification,
  } = props;
  if (!heading || !options || !selectedOptions) {
    return null;
  }
  return (
    <div className={css.sectionMultiEnum}>
      <p className={css.minimumReqTitle}>Minimum requirements</p>
      {levelOfEducation && levelOfEducation === 'graduateDegree' ? (
        <div className={css.detailsRowContinue}>
          <p className={css.detailLabel}>
            <IconEducationNew /> Education Level:
          </p>
          <p>Graduate Degree</p>
        </div>
      ) : levelOfEducation === 'undergraduateDegree' ? (
        <div className={css.detailsRowContinue}>
          <p className={css.detailLabel}>
            <IconEducationNew /> Education Level:
          </p>
          <p>Undergraduate Degree</p>
        </div>
      ) : levelOfEducation === 'doctorateDegree' ? (
        <div className={css.detailsRowContinue}>
          <p className={css.detailLabel}>
            <IconEducationNew /> Education Level:
          </p>
          <p>Doctorate Degree</p>
        </div>
      ) : levelOfEducation === 'highschool' ? (
        <div className={css.detailsRowContinue}>
          <p className={css.detailLabel}>
            <IconEducationNew /> Education Level:
          </p>
          <p>Highschool Diploma</p>
        </div>
      ) : levelOfEducation === 'notApplicaple' ? (
        <div className={css.detailsRowContinue}>
          <p className={css.detailLabel}>
            <IconEducationNew /> Education Level:
          </p>
          <p>N/A</p>
        </div>
      ) : levelOfEducation === 'other' && otherLevelEducationContent ? (
        <div className={css.detailsRowContinue}>
          <p className={css.detailLabel}>
            <IconEducationNew /> Education Level:
          </p>
          <p>{otherLevelEducationContent}</p>
        </div>
      ) : null}

      {currentCertification && currentCertification === 'nonUSbasedSLP' ? (
        <div className={css.detailsRowContinue}>
          <p className={css.detailLabel}>
            <IconCertificate /> Certification:
          </p>
          <p>Non-US based SLP</p>
        </div>
      ) : currentCertification === 'SLPA' ? (
        <div className={css.detailsRowContinue}>
          <p className={css.detailLabel}>
            <IconCertificate /> Certification:
          </p>
          <p>SLPA</p>
        </div>
      ) : currentCertification === 'CF-SLP' ? (
        <div className={css.detailsRowContinue}>
          <p className={css.detailLabel}>
            <IconCertificate /> Certification:
          </p>
          <p>CF-SLP</p>
        </div>
      ) : currentCertification === 'CCC-SLP' ? (
        <div className={css.detailsRowContinue}>
          <p className={css.detailLabel}>
            <IconCertificate /> Certification:
          </p>
          <p>CCC-SLP</p>
        </div>
      ) : currentCertification === 'other' && otherCurrentCertification ? (
        <div className={css.detailsRowContinue}>
          <p className={css.detailLabel}>
            <IconCertificate /> Certification:
          </p>
          <p>{otherCurrentCertification}</p>
        </div>
      ) : null}

      <div>
        {trueTrainingComp && (
          <p>
            <IconEducation /> Previous Training(s)
          </p>
        )}

        <PropertyGroup
          id="ListingPage.amenities"
          options={options}
          selectedOptions={selectedOptions}
          categoryPreviousOther={categoryPreviousOther}
        />
      </div>
    </div>
  );
};

export default SectionMultiEnumMaybe;
